import EmployeeNameField from "./EmployeeNameField.vue";
import EmployeeFirstFamilyNameField from "./EmployeeFirstFamilyNameField.vue";
import EmployeeSecondFamilyNameField from "./EmployeeSecondFamilyNameField.vue";
import EmployeeCompanyEmailField from "./EmployeeCompanyEmailField.vue";
import EmployeePhoneNo2Field from "./EmployeePhoneNo2Field.vue";
import EmployeeAddressField from "./EmployeeAddressField.vue";
import EmployeeAddress2Field from "./EmployeeAddress2Field.vue";
import EmployeePrivateEmailField from "./EmployeePrivateEmailField.vue";
import EmployeePrivatePhoneField from "./EmployeePrivatePhoneField.vue";
import EmployeeExtensionField from "./EmployeeExtensionField.vue";
import EmployeeBirthDayField from "./EmployeeBirthDayField.vue";
import EmployeeCassExpirationDateField from "./EmployeeCassExpirationDateField.vue";
import EmployeeDniExpirationDateField from "./EmployeeDniExpirationDateField.vue";
import EmployeeDniNumberField from "./EmployeeDniNumberField.vue";
import EmployeeNoAuthorizationResidenteField from "./EmployeeNoAuthorizationResidenteField.vue";
import EmployeeSocialSecurityField from "./EmployeeSocialSecurityField.vue";
import EmployeeIbanField from "./EmployeeIbanField.vue";
import EmployeeSwiftCodeField from "./EmployeeSwiftCodeField.vue";

export {
  EmployeeNameField,
  EmployeeFirstFamilyNameField,
  EmployeeSecondFamilyNameField,
  EmployeeCompanyEmailField,
  EmployeePhoneNo2Field,
  EmployeeAddressField,
  EmployeeAddress2Field,
  EmployeePrivateEmailField,
  EmployeePrivatePhoneField,
  EmployeeExtensionField,
  EmployeeBirthDayField,
  EmployeeCassExpirationDateField,
  EmployeeDniExpirationDateField,
  EmployeeDniNumberField,
  EmployeeNoAuthorizationResidenteField,
  EmployeeSocialSecurityField,
  EmployeeIbanField,
  EmployeeSwiftCodeField,
};
