<template>
  <suc-select-component
    :title="$t('pages.profile.tabs.personal_data.swift_code')"
    :options="options"
    label="name"
    v-bind="$attrs"
    v-on="$listeners"
    :reduce="(bank) => bank.code"
  >
    <template #no-options>{{ $t("components.fields.no_options") }}</template>
  </suc-select-component>
</template>

<script>
import { SucSelectComponent, SUC_SELECT_CHANGED_EVENT } from "@/components/form";
import api from "@/api/user";

export default {
  components: {
    SucSelectComponent,
  },
  data() {
    return {
      options: [],
      value: null,
    };
  },
  methods: {
    async init() {
      const { data } = await api.getSwiftCodes();
      this.options = data;
    },
    onChangeField(option) {
      this.value = option;
      this.$emit(SUC_SELECT_CHANGED_EVENT, this.$deepCopy(this.value));
    },
  },
};
</script>

<style scoped></style>
